import "./App.css"
import Header from "./Header"
import Footer from "./Footer"
import MainContent from "./MainContent"


function App() {
  return(
      <div className="content">
            <Header />
            <MainContent />
            <Footer />
      </div>
  )
}

export default App